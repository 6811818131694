.printOnlyBlock {
	display: none !important;
}

@media print { 
	
	@page {
    	size: portrait;
    }
	
	header {
		position: static !important;
		background: transparent !important;
		border: none !important;
		height: auto !important;
	}
	
	.MuiDialog-paper {
		padding: 0;
		margin: 0;
	}
	.MuiBackdrop-root {
		display: none;
	}
	
	.printModal #root {
		display: none !important;
	}
	
	
	header > .MuiToolbar-root {
		padding: 1rem !important;
	}
	
	h1.patientName {
		background: transparent;
		border: none;
		padding: 0;
		height: 4rem;
	}
	
	.MuiInput-underline:before {
		border: none !important;	
	}
	
	button,
	a,
	#leftToolbar,
	.printHidden {
		display: none !important;
	}
	
	.printOnlyBlock {
		display: block !important;
	}
	.printOverflowVisible {
		overflow: visible !important;
	}
	.printPositionRelative {
		position: relative !important;
	}
	.printDisplayBlock {
		display: block !important;
	}
	header {
		position: fixed !important;
		top: 0;
	}
	main {
		padding: 0 !important;
		margin: 0 !important;
		padding-top: 14rem !important;
	}	
	
	main.billing {
		padding-top: 3rem !important;
	}
	
	#root {
		border: solid 0.8rem #13BFF4;
		display: block !important;
	}
	
	.print30DayBlock {
       position: static !important;
       overflow: visible !important;
   }
		
	.print30DayModalContainer {
		overflow-y: visible !important;	
	}

	header.billing { display: none; }
	
	.printStatic { 
		position: static !important;
	}
	
	.printMarginTop2 {
		margin-top: 2rem;
	}
	
	.printSmallText * {
		font-size: 1.2rem !important;
	}

   thead {display: table-header-group;}
}

