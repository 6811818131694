html {
	font-size: 10px;
    width: 100%;
    height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
    width: 100%;
    height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
	display:flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    min-width: 1024px;
	overflow: hidden;
}

:root {
  --amplify-primary-color: #13BFF4;
  --amplify-primary-tint: #13BFF4;
  --amplify-primary-shade: #13BFF4;
}

amplify-container {
	width: 50%;
	transform: scale(2.0) translate(25%, 26%);
	margin-top: 10rem;
	text-align: center;
}

amplify-authenticator {
	margin: 0;
}
